import React from 'react';

const OverpaymentsFAQ = () => ([
    {
      title: 'What are mortgage overpayments?',
      description: (
        <>
          A mortgage overpayment is an additional payment on top of your regular monthly payments set out in your mortgage terms. Usually, you can make 10% of the mortgage amount as an overpayment.
        </>
      ),
    },
    {
      title: 'What are the benefits of making an overpayment?',
      description: (
        <>
          The key benefit of making a mortgage overpayment is that you could save money and become mortgage-free, faster.<br/><br/>
          The benefit of making an overpayment is that you will be directly reducing the mortgage balance and retaining more equity in your property, which results in you taking less time to repay the whole mortgage debt.
        </>
      ),
    },
    {
      title: 'How do you make a mortgage overpayment?',
      description: (
        <>
          Often, the lender’s default is to keep the term the same and reduce your monthly repayments, which won't have an impact on reducing your mortgage interest, so it's best to call and make sure your preferences are updated before you start making a mortgage overpayments.<br/><br/>
          Usually, you can make 10% of the mortgage amount as an overpayment, before the lender will charge an early repayment charge (ERC).<br/><br/>
          Calculate how much you can afford to overpay and if you want to make the payments in lump sums or regular smaller overpayments.<br/><br/>
          You can make overpayments easily via the Sprive app easily, or through your bank.

        </>
      ),
    },
    {
      title: 'Are there any potential drawbacks to mortgage overpayments?',
      description: (
        <>
        There are some things to take into consideration when making overpayments. Some mortgages might have early repayment charges or restrictions on overpayments, so it's important to check your mortgage terms before making extra payments.<br/><br/>
        It can be difficult to get the money back once you've made the payment to your lender. It's wise to have an emergency fund aside in case of any unforeseen circumstances so you have access to this before making overpayments on your mortgage either as a lump sum or as regular small payments.
        </>
      ),
    },
    {
      title: 'Can I make mortgage overpayments on any type of mortgage?',
      description: (
        <>
          Most mortgage types allow for overpayments, but it's wise to confirm with your lender. Most mortgages allow a 10% overpayment allowance.<br/><br/>
          A repayment mortgage is where you pay back to capital and the interest at the same time. A fixed-rate and a variable-rate mortgage are types of repayment mortgage and usually allow for making overpayments but tend to come with early repayment charges so it's best to check the terms.

        </>
      ),
    },
    {
      title: 'How does overpaying your mortgage affect my monthly payments?',
      description: (
        <>
          When you make overpayments, either in regular small payments or as a lump sum, your total mortgage balance decreases, which could lead to a decrease in the amount of interest you owe each month. This reduction in interest could result in a smaller portion of your monthly payment going towards interest and a larger portion towards the principal balance.<br/><br/>
          However, your monthly payment might remain the same unless you specifically request a reduction from your lender, so it's important to contact your lender to update them on your overpayment preferences on your mortgage deal to make sure you're having the benefits of overpaying on your mortgage.
        </>
      ),
    },
    {
      title: 'Can I choose how often I make mortgage overpayments?',
      description: (
        <>
          Depending on your mortgage terms, you might have the flexibility to choose when and how often you make overpayments. Some lenders allow for one-time lump-sum payments, while others let you increase your monthly mortgage payment amount.
        </>
      ),
    },
    {
      title: 'Is there a minimum amount I need to overpay to see an impact?',
      description: (
        <>
          Lump sum overpayments can lead to quick results in terms of interest savings and mortgage payoff acceleration.<br/><br/>
          There isn't usually a minimum amount you can make as an overpayment. Even small overpayments can have a positive impact over time.<br/><br/>
          You can use a mortgage overpayment calculator to see how much you could save in interest and years saved off your mortgage term.
        </>
      ),
    },
    {
      title: 'Are mortgage overpayments suitable for everyone?',
      description: (
        <>
          Mortgage overpayments are a great option for those with extra funds and a desire to pay off their mortgage faster and reduce the mortgage term. However, individuals with high-interest debts or those who haven't established an emergency fund might have other financial priorities.<br/><br/>
          Remember, every individual's financial situation is unique, so it's important to consider your own goals, circumstances, and lender terms before deciding on overpaying your mortgage.
        </>
      ),
    },
    {
      title: 'Can I make overpayments with Sprive?',
      description: (
        <>
          You can use Sprive to make easy one-tap overpayments straight to your lender. You can use Sprive's autosaving AI and we'll set aside spare cash for you and then you can put this towards your mortgage in one tap. Or you can make one-off mortgage overpayments when you like.<br/><br/>
          You can use our mortgage overpayment calculator to see how much you could save in interest and years saved off your mortgage term.
        </>
      ),
    },
  ]);

export default OverpaymentsFAQ;
